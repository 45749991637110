<template>
  <div class="app">
    <!-- 右侧浮层 -->
    <div v-if="showImage">
      <div class="floatingLayer">
        <i class="el-icon-circle-close closeButton" @click="closeImage"></i>
        <!-- 图片作为浮层 -->
        <a href="javascript:void(0)"><img class="floatingImage"
                                          :src="websiteFloat.adPic" @click="openWebsite(websiteFloat.adLink)"></a>
      </div>
    </div>
    <div class="bannerStyle">
      <el-carousel trigger="click" height="600px">
        <el-carousel-item v-for="(item, index) in banner" :key="index">
          <div>
            <img :src="item.img" alt="" class="bannerItem"/>
            <div class="bannerItem-box">
              <div class="bannerItem-box-title">{{ item.title1 }}</div>
              <div class="bannerItem-box-subTitle">{{ item.title2 }}</div>
              <div class="bannerItem-box-title">{{ item.title3 }}</div>
              <div class="tips">
                <div v-for="(tip, index) in item.tips" :key="index">{{ tip }}</div>
              </div>
              <div class="btn" @click="toLogin">免费注册</div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="iconsBoxDesc">
      <div class="title">中马互贸跨境综合服务</div>
      <div class="subTitle">无论中国企业，还是马来西亚企业，均可找到适合的服务。</div>
    </div>
    <div class="serverList">
      <div class="items" v-for="(item, index) in serverList" :key="index">
        <img class="circle" :src="item.img" alt=""/>
        <p>{{ item.text }}</p>
      </div>
    </div>
    <div id="backgroundDiv" class="iconsBox2">
      <div class="title">
        <div>动态要闻</div>
        <p>为中马贸易伙伴提供最新资讯，时刻掌握最新热点</p>
      </div>
      <el-card class="box-card" style="margin-top: 40px;padding: 40px 20px;">
        <el-row type="flex">
          <el-col :span="4">
            <div v-for="(item,index) in tabs2" :key=index
                 style="cursor: pointer" @click="handleTabClick(item,index)"
                 :class="{ 'active-tab': activeTabIndex === index }">
              <div style="display: flex;align-items: center;justify-content: center;line-height: 80px;">
                <img :src="activeTabIndex === index ? tabsIconList[index].img2 : tabsIconList[index].img1"
                     class="newsTabsPic"/>
                <span :class="activeTabIndex === index? 'active-text':'active-text2'"
                      class="newsTabsText">{{ item.dictLabel }}</span>
              </div>
            </div>
          </el-col>
          <el-col :span="20">
            <div v-for="(item, index) in rightList" :key="index" style="display: flex">
              <div style="cursor: pointer" @click="lookNewsInfo(item)" v-if="item.pic">
                <img :src="item.pic" class="newsPic" style="" alt=""/>
              </div>
              <div
                  style="display: flex;flex-direction: column;align-items: flex-start;justify-content: center;line-height: 30px;margin-left: 20px;cursor: pointer"
                  @click="lookNewsInfo(item)">
                <div class="webTitle">
                  {{ item.webTitle }}
                </div>
                <div class="newsTime">发布时间： {{ item.newsTime }}</div>
                <div class="introduction">
                  {{ item.introduction }}
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-card>
    </div>
    <div class="serve">
      <div class="cardBox">
        <div class="title">
          <div>产品与服务</div>
          <p>为中马互贸易伙伴提供各类政策、贸易、金融的相关产品与配套服务</p>
        </div>
        <div style="margin-top: 20px" v-if="imageCapsule.length > 0">
          <el-carousel height="160px">
            <el-carousel-item v-for="(item,index) in imageCapsule" :key="index">
              <a href="javascript:void(0)"><img :src="item.adPic" @click="openWebsite(item.adLink)"></a>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="cardList">
          <div class="cardItem" v-for="(item, index) in productServices" :key="index">
            <div class="title">
              <p>{{ item.title }}</p>
              <img :src="cardList[index].img" alt=""/>
            </div>
            <p class="text">{{ item.summary }}</p>
            <div class="btn" @click="lookDetail(item)">查看详情</div>
          </div>
        </div>
      </div>
    </div>
    <div class="tabsBox">
      <div class="titleBox">
        <div class="title">
          <p
              :class="item.name == activeName ? 'active' : ''"
              v-for="(item, index) in tabs"
              :key="index"
              @click="tabClick(item.name)"
          >
            {{ item.name }}
          </p>
        </div>
      </div>
      <div
          class="tabsContent"
          v-for="(item, index) in tabs"
          :key="index"
          v-show="item.name == activeName"
      >
        <div class="left">
          <img :src="item.img" alt="" style="width: 100%;"/>
        </div>
        <div class="right">
          <div v-for="(i, id) in item.des" :key="id">
            <p>{{ i.title }}</p>
            <span>{{ i.text }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="unit">
      <div class="title">
        <p>园区合作单位</p>
        <span>致力于为中国和马来西亚的企业提供更好的营商环境</span>
      </div>
      <div class="list">
        <div class="listItem" v-for="(item, index) in unitList" :key="index">
          <div>
            <img :src="item.img1" alt=""/>
            <p>{{ item.name1 }}</p>
            <span>{{ item.text1 }}</span>
          </div>
          <div>
            <img :src="item.img2" alt=""/>
            <p>{{ item.name2 }}</p>
            <span>{{ item.text2 }}</span>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        width="50%">
      <div v-html="detailContent"></div>
      <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">关　闭</el-button>
            <el-button type="primary" @click="toLogin()">立即注册</el-button>
          </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showImage: false,
      websiteFloat: {
        // 网站图片地址
        adPic: null,
        // 网站浮层链接
        adLink: null,
      },
      imageCapsule: [],
      banner: [
        {
          img: require("../assets/home/banner04.png"),
          title1: "马来西亚及东盟十国",
          title2: "全球企业信用报告查询",
          title3: "年销超80万份 230个国家客户购买",
          tips: ["超三亿企业信息", "付款 / 还款能力评估", "综合信用 / 实力评估", "···"],
        },
        {
          img: require("../assets/home/banner01.png"),
          title1: "精准对接",
          title2: "马来西亚及东盟十国",
          title3: "优质、可靠的生意资源",
          tips: ["政策服务", "金融服务", "认证服务", "信息服务", "···"],
        },
        {
          img: require("../assets/home/banner02.png"),
          title1: "为好的企业",
          title2: "找到对的钱",
          title3: "基于区块链的跨境金融服务",
          tips: ["中信银行", "中国银行", "马来亚银行", "联昌银行", "···"],
        },
        {
          img: require("../assets/home/banner03.png"),
          title1: "星火链网 x 跨境综合服务",
          title2: "国家区块链基础设施赋能",
          title3: "基于新基建的跨境综合服务",
          tips: ["标准互通", "贸易互通", "产业互通", "人流互通", "···"],
        },
      ],
      serverList: [
        {
          img: require("../assets/home/icon1.png"),
          text: "政策便利",
        },
        {
          img: require("../assets/home/icon2.png"),
          text: "贸易便利",
        },
        {
          img: require("../assets/home/icon3.png"),
          text: "金融便利",
        },
        {
          img: require("../assets/home/icon4.png"),
          text: "认证便利",
        },
        {
          img: require("../assets/home/icon5.png"),
          text: "区块链支撑",
        },
        {
          img: require("../assets/home/icon6.png"),
          text: "合作共赢",
        },
      ],
      cardList: [
        {
          img: require("../assets/home/cardIcon1.png"),
          title: "外资企业注册绿色通道",
          text: "帮助马来西亚的企业，在中国迅速完成企业主体的设立程序。并提供完善、专业的金融服务。园区与各级政府部门、各大金融机构紧密协同，确保客户的信息资产安全、融资渠道畅通。",
        },
        {
          img: require("../assets/home/cardIcon2.png"),
          title: "私营企业注册绿色通道",
          text: "为有意与马来西亚进行贸易的中国企业提供高效注册通道，同时提供专业的渠道资源和供应链对接服务。企业可以快速获得必要的资质并与适合的境内外供应商建立联系。",
        },
        {
          img: require("../assets/home/cardIcon7.png"),
          title: "商务签证线上办理",
          text: "马来西亚商务签证线上办理，为企业提供贸易便利，打通专属绿色通道，相比其他第三方通道，中马互贸平台的签证服务具备更省时、更省力、更省心的特点。",
        },
        {
          img: require("../assets/home/cardIcon3.png"),
          title: "NRA账户快速设立",
          text: "便捷的NRA银行账户开设服务。简化开户过程、加速资金流动，满足企业在跨境交易、投资和资金管理方面的需求。园区与多家合作银行建立密切合作关系，确保开设NRA银行账户的高效和准确。",
        },
        {
          img: require("../assets/home/cardIcon4.png"),
          title: "FDI账户快速设立",
          text: "为用户提供快捷的FDI企业银行账户开设服务。帮助企业快速、顺利地开立FDI企业银行账户。园区提供全方位的咨询和指导，帮助企业了解不同银行的服务特点、政策要求和流程规定。",
        },
        {
          img: require("../assets/home/cardIcon6.png"),
          title: "马来西亚企业大数据",
          text: "全面的马来西亚企业信息查询，包括工商、税务、海关、征信评级等多个方面的信息数据，帮助客户更好地了解目标企业的运营情况，并且做出明智的商业决策。",
        },
      ],
      activeName: "政策便利服务",
      tabs: [
        {
          name: "政策便利服务",
          img: require("../assets/home/tabs1.png"),
          des: [
            {
              title: "提升效率：各类主体或账户手续办理专享绿色通道。",
              text: "更简单、更便捷，开户办理提供一站式服务。",
            },
            {
              title: "节省成本：园区入驻企业可享受最新的中马双方的政策扶持。",
              text: "中国及马来西亚双方政策扶持，助力企业节省资金。",
            },
            {
              title: "行业资讯：双边互贸新政个性化推送、精准触达。",
              text: "为企业决策提供最新的、可靠的行业及政策消息。",
            },
          ],
        },
        {
          name: "贸易便利服务",
          img: require("../assets/home/tabs2.png"),
          des: [
            {
              title: "融资便捷：NRA、FDI等投融资金融产品整合输出。",
              text: "跨境金融服务专家，提供专业的投融资方案并落地交付。",
            },
            {
              title: "资金增值：中马双边贸易居间对接，商会资源和资讯对接。",
              text: "助力企业对接产品及客户资源，匹配适合的贸易伙伴。",
            },
            {
              title: "投资：中国及马来西亚优质企业数据库。",
              text: "跨境投资产品整合，为企业提供适合的投资渠道。",
            },
          ],
        },
        {
          name: "国际认证服务",
          img: require("../assets/home/tabs3.png"),
          des: [
            {
              title: "权威认证：马来西亚国际清真认证（HALAL认证）。",
              text: "全球穆斯林国家认可的资质，平台客户享有快速办理渠道。",
            },
            {
              title: "验真服务：中国及马来西亚原产地认证核验。",
              text: "平台客户可享受中国及马来西亚原产地认证的验真服务。",
            },
            {
              title: "增信服务：其他中马贸易相关资质认证服务。",
              text: "各类中马贸易相关资质认证协助办理及资源匹配。",
            },
          ],
        },
        {
          name: "大数据服务",
          img: require("../assets/home/tabs4.png"),
          des: [
            {
              title: "马来西亚企业海量信息查询。",
              text: "马来西亚企业信息大数据查询，工商、税务、海关、征信数据。",
            },
            {
              title: "对接马来西亚CTOS马来西亚征信报告系统。",
              text: "马来西亚头部信贷资讯服务公司，受财政部信贷报告机构注册部监管。",
            },
            {
              title: "中马贸易相关增值服务。",
              text: "可定制中马贸易调研报告等周边增值服务。",
            },
          ],
        },
      ],
      unitList: [
        {
          img1: require("../assets/home/unit01.png"),
          name1: "MYEG集团",
          text1: "马来西亚电子政务服务商",
          img2: require("../assets/home/unit02.png"),
          name2: "中信银行",
          text2: "提供境内外企业金融服务",
        },
        {
          img1: require("../assets/home/unit03.png"),
          name1: "CardBiz",
          text1: "马来西亚支付服务商",
          img2: require("../assets/home/unit04.png"),
          name2: "中国银行",
          text2: "提供境内外企业金融服务",
        },
        {
          img1: require("../assets/home/unit05.png"),
          name1: "Al Hamra集团",
          text1: "马来西亚头部认证机构",
          img2: require("../assets/home/unit06.png"),
          name2: "工商银行",
          text2: "提供境内外企业金融服务",
        },
        {
          img1: require("../assets/home/unit07.png"),
          name1: "新发地农产品",
          text1: "新发地农产品股份有限公司",
          img2: require("../assets/home/unit08.png"),
          name2: "Maybank",
          text2: "马来西亚最大的银行",
        },
        {
          img1: require("../assets/home/unit09.png"),
          name1: "星火链网",
          text1: "为平台提供区块链底层服务",
          img2: require("../assets/home/unit10.png"),
          name2: "CIMB BANK",
          text2: "联昌国际银行",
        },
      ],
      tabsIconList: [
        {
          img1: require("../assets/home/icon7.png"),
          img2: require("../assets/home/icon10.png"),
        },
        {
          img1: require("../assets/home/icon8.png"),
          img2: require("../assets/home/icon11.png"),
        },
        {
          img1: require("../assets/home/icon9.png"),
          img2: require("../assets/home/icon12.png"),
        }
      ],
      tabs2: [],
      tabValue: null,
      rightList: [],
      activeTabIndex: 0,
      productServices: [],
      title: null,
      detailContent: null,
      registrationLink: null,
      dialogVisible: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    // 在离开当前页面时滚动到顶部
    window.scrollTo(0, 0);
    next();
  },
  created() {
    this.getAdvert();
    this.getTabs();
    this.getProductServices();
  },
  methods: {
    lookDetail(item) {
      console.log(item)
      this.title = item.title;
      this.detailContent = item.detailContent;
      this.registrationLink = item.registrationLink;
      this.dialogVisible = true;
    },
    getProductServices() {
      this.$axios
          .get("https://news.mycntrade.com/api/zm/queryProductServicesList")
          .then((res) => {
            if (res) {
              this.productServices = res;
            }
          });
    },
    handleTabClick(item, index) {
      this.tabValue = item.dictValue;
      this.activeTabIndex = index;
      this.getList();
    },
    getTabs() {
      this.$axios
          .post("https://platformdev.mycntrade.com/api/web/business/queryTypes")
          .then((res) => {
            if (res) {
              this.tabs2 = res.data;
              this.tabValue = this.tabs2[0].dictValue;
              this.getList();
            }
          });
    },
    // getTabs() {
    //   this.$axios
    //       .get("https://news.mycntrade.com/api/zm/queryTypeList")
    //       .then((res) => {
    //         if (res) {
    //           this.tabs2 = res;
    //           this.tabValue = this.tabs2[0].dictValue;
    //           this.getList();
    //         }
    //       });
    // },
    getList() {
      let opt = new FormData();
      opt.append('typeId', this.tabValue);
      opt.append('pageNum', "1");
      opt.append('pageSize', "10");
      // 启动Loading
      let loadingInstance = this.$loading({
        lock: false, // 设置lock为false以避免修改overflow样式
        text: '加载中...', // 加载时显示的文本
        background: 'rgba(0, 0, 0, 0.7)', // 加载遮罩背景色
      });
      this.$axios
          .post("https://platformdev.mycntrade.com/api/web/business/queryTypeList", opt)
          .then((res) => {
            if (res) {
              this.rightList = res.data.rows.splice(0, 3);
            }
          }).catch((error) => {
        console.error("请求出错：", error);
      })
          .finally(() => {
            // 不论请求成功还是失败，都需要关闭Loading
            loadingInstance.close();
          });
      ;
    },
    // getList() {
    //   let opt = new FormData();
    //   opt.append('type', this.tabValue);
    //   opt.append('pageNum', "1");
    //   opt.append('pageSize', "10");
    //   // 启动Loading
    //   let loadingInstance = this.$loading({
    //     lock: false, // 设置lock为false以避免修改overflow样式
    //     text: '加载中...', // 加载时显示的文本
    //     background: 'rgba(0, 0, 0, 0.7)', // 加载遮罩背景色
    //   });
    //   this.$axios
    //       .post("https://news.mycntrade.com/api/zm/queryHomePageList", opt)
    //       .then((res) => {
    //         if (res) {
    //           this.rightList = JSON.parse(res.data).splice(0, 3);
    //         }
    //       }).catch((error) => {
    //     console.error("请求出错：", error);
    //   })
    //       .finally(() => {
    //         // 不论请求成功还是失败，都需要关闭Loading
    //         loadingInstance.close();
    //       });
    //   ;
    // },
    getAdvert() {
      this.$axios
          .post("https://platformdev.mycntrade.com/api/web/business/getAdvertData")
          .then((res) => {
            if (res.code === 0) {
              let data = res.data.rows
              if (data.length > 0) {
                for (let i in data) {
                  let item = data[i]
                  if (item.adPosition === '2' && item.isArrive === 1) {
                    this.websiteFloat.adPic = item.adPic;
                    this.websiteFloat.adLink = item.adLink;
                    this.showImage = true;
                  }
                  if (item.adPosition === '3' && item.isArrive === 1) {
                    this.imageCapsule.push(item);
                  }
                }
              }
            }
          });
    },
    tabClick(val) {
      this.activeName = val;
    },
    toLogin() {
      this.dialogVisible = false;
      window.open("https://platformdev.mycntrade.com/");
    },
    closeImage() {
      this.showImage = false;
    },
    openWebsite(url) {
      window.open(url);
    },
    lookNewsInfo(news) {
      window.localStorage.setItem('news', JSON.stringify(news))
      this.$router.push({path: '/newsInfo', query: {news: news}});
    }
  },
};
</script>

<style lang="scss" scoped>

.app {
  overflow-x: hidden;
}

/* 右侧浮层样式 */
.floatingLayer {
  position: fixed;
  //top: 75%; /* 垂直居中 */
  top: 65%; /* 垂直居中 */
  right: 0;
  transform: translateY(-50%);
  background-color: transparent; /* 透明背景 */
  z-index: 9999;
}

/* 图片样式 */
.floatingImage {
  width: 220px; /* 设置图片宽度 */
  //height: 350px; /* 保持宽高比例 */
}

/* 关闭按钮样式 */
.closeButton {
  position: absolute;
  top: -4px;
  right: 0px;
  cursor: pointer;
  color: #666666;
  font-size: 20px;
}

.bannerStyle {
  background: #2a50b7;
}

.bannerItem {
  float: right;
  position: relative;
  margin-right: -10%;
}

.bannerItem-box {
  position: absolute;
  left: 12%;
  bottom: 150px;
  color: #fff;
  font-weight: 400;
}

.bannerItem-box-title {
  font-size: 33px;
  line-height: 40px;
}

.bannerItem-box-subTitle {
  font-size: 55px;
  line-height: 60px;
}

.tips {
  font-size: 16px;
  line-height: 22px;
  color: #81ecff;
  margin-top: 10px;

  div {
    display: inline-block;
    padding: 6px 14px;
    border: 1px solid #81ecff;
    border-radius: 24px;
    margin-right: 10px;
  }
}

.btn {
  width: 116px;
  background: #ffffff;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 400;
  line-height: 49px;
  color: #2a50b7;
  text-align: center;
  margin-top: 14px;
  cursor: pointer;
}

.btn:hover {
  background: #63c918;
  color: #ffffff;
}

.iconsBoxDesc {
  font-weight: 400;
  text-align: center;
  width: 100%;
  margin: 40px auto;

  .title {
    font-size: 40px;
    line-height: 56px;
    color: #444444;
  }

  .subTitle {
    font-size: 16px;
    line-height: 22px;
    color: #666666;
  }
}

.serverList {
  display: flex;
  @media screen and (min-width: 1440px) {
    width: 50%;
  }
  width: 70%;
  justify-content: space-between;
  margin: 0 auto;
  margin-bottom: 52px;

  .items {
    div {
      width: 116px;
      height: 116px;
      border: 1px solid #63c918;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        display: block;
      }
    }

    p {
      margin-top: 26px;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      color: #444444;
      text-align: center;
    }
  }

  .items:hover {
    div {
      background: #f6f6f6;
      border: 4px solid #63c918;
    }

    p {
      color: #5A9CF8;
    }
  }
}

.iconsBox2 {
  font-weight: 400;
  text-align: center;
  margin: 0 auto;

  .title {
    margin-top: 42px;

    div {
      font-size: 40px;
      line-height: 56px;
      color: #FFFFFF;
    }

    p {
      font-size: 16px;
      line-height: 22px;
      color: #FFFFFF;
    }
  }
}

.newsTabsPic {
  @media screen and (min-width: 1440px) {
    width: 50px;
    height: 50px;
  }
  width: 25px;
  height: 25px;
}

.newsTabsText {
  @media screen and (min-width: 1440px) {
    font-size: 18px;
    margin-left: 5px;
  }
  font-size: 14px;
  margin-left: 3px;
}

.newsPic {
  @media screen and (min-width: 1440px) {
    width: 240px;
    height: 125px;
    margin: 10px 20px;
  }
  width: 120px;
  height: 62px;
  margin: 5px 10px;
  border-radius: 6px;
}

.webTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media screen and (min-width: 1660px) {
    max-width: 600px;
    font-size: 24px;
  }
  @media screen and (min-width: 1900px) {
    max-width: 700px;
    font-size: 24px;
  }
  max-width: 500px;
  font-size: 18px;
  color: #2a50b7;
  text-align: left;
}

.newsTime {
  @media screen and (min-width: 1440px) {
    font-size: 16px;
  }
  font-size: 14px;
  color: #999999;
  text-align: left
}

.introduction {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 500px;
  @media screen and (min-width: 1440px) {
    font-size: 16px;
  }
  font-size: 14px;
  color: #999999;
  text-align: left;
}

.serve {
  width: 100%;
  background: #f6f6f6;
}

.cardBox {
  font-weight: 400;
  text-align: center;
  padding: 50px 0;
  width: 72%;
  margin: 0 auto;

  .title {
    div {
      font-size: 40px;
      line-height: 56px;
      color: #444444;
    }

    p {
      font-size: 16px;
      line-height: 22px;
      color: #666666;
      padding-bottom: 8px;
    }
  }

  .cardList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .cardItem {
      width: 32%;
      //height: 330px;
      //padding: 21px 44px 36px 30px;
      padding: 20px;
      background: #fff;
      margin-top: 30px;

      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 9px;
        border-bottom: 1px solid #dddddd;
        margin-bottom: 23px;

        p {
          font-size: 26px;
          font-weight: 400;
          line-height: 36px;
          color: #2a50b7;
        }

        img {
          display: block;
          @media screen and (max-width: 1366px) {
            width: 45px;
            height: 45px;
          }
          @media screen and (max-width: 1280px) {
            width: 35px;
            height: 35px;
          }
          width: 65px;
          height: 65px;
        }
      }

      .text {
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
        color: #666666;
        text-align: start;
        height: 180px;
      }

      .btn {
        width: 95px;
        height: 40px;
        background: #ffffff;
        border: 1px solid #5A9CF8;
        line-height: 40px;
        border-radius: 20px;
        font-size: 15px;
        font-weight: 400;
        color: #5A9CF8;
        margin-top: 24px;
        cursor: pointer;
      }
    }

    .cardItem:hover {
      background: #2a50b7;
      border-bottom: 1px solid #ffffff;

      .title {
        p {
          color: #ffffff;
        }
      }

      .text {
        color: #ffffff;
      }

      .btn {
        border: 1px solid #ffffff;
        color: #ffffff;
        background: #2a50b7;
      }
    }
  }
}

.tabsBox {
  .titleBox {
    background: #f6f6f6;
  }

  .title {
    line-height: 78px;
    display: flex;
    justify-content: center;

    p {
      width: 214px;
      text-align: center;
      font-size: 22px;
      font-weight: 600;
      color: #666666;
      cursor: pointer;
    }

    p:hover {
      color: #2a50b7;
    }

    .active {
      color: #2a50b7;
    }
  }

  .tabsContent {
    padding: 44px 0 85px;
    width: 72%;
    margin: 0 auto;
    display: flex;
    align-items: center;

    .left {
      width: 50%;
    }

    img {
      display: block;
      margin-right: 39px;
      border-radius: 8px;
    }

    .right {
      div {
        height: 120px;
        padding: 30px 0px 30px 30px;

        p {
          font-size: 22px;
          font-weight: 400;
          color: #2a50b7;
          line-height: 27px;
        }

        span {
          font-size: 16px;
          font-weight: 400;
          line-height: 27px;
          color: #666666;
        }
      }

      div:hover {
        background: #f6f6f6;
        border-radius: 8px;
      }
    }
  }
}

.line {
  width: 86%;
  height: 1px;
  background: #dddddd;
  margin: 0 auto;
}

.unit {
  padding: 54px 0 115px;
  font-weight: 400;

  .title {
    text-align: center;

    p {
      font-size: 40px;
      line-height: 56px;
      color: #444444;
    }

    span {
      font-size: 14px;
      line-height: 20px;
      color: #666666;
    }
  }

  .list {
    display: flex;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-top: 46px;

    .listItem {
      height: 492px;
      border-right: 1px solid #dddddd;
      @media screen and (min-width: 1600px) {
        padding: 0 20px;
      }
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      div {
        margin-bottom: 22px;
      }

      p {
        font-size: 22px;
      }

      span {
        color: #444;
      }
    }

    .listItem:last-of-type {
      border: none;
    }

    img {
      display: block;
      margin-bottom: 14px;
    }
  }
}

.circle:hover {
  background-color: #EBF3FF; /* 鼠标悬停时改变背景颜色，可以根据需要调整 */
  border: solid 2px #5A9CF8;
}

.circle {
  width: 88px;
  height: 88px;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  padding: 15px;
  border: solid 1px #5A9CF8;
  transition: filter 0.3s ease-in-out;
}

#backgroundDiv {
  background-image: url('../assets/home/bigPic.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  padding-bottom: 60px;
  padding-top: 2px;
}

.el-card {
  width: 72%;
  margin: 0 auto;
}

.active-text {
  color: #5A9CF8; /* 激活时的文本颜色为蓝色，根据需要调整 */
}

.active-text2 {
  color: #999999;
}
</style>
