<template>
  <div class="guide">
    <div class="header">
      <img src="../../assets/guide/header.png" alt=""/>
    </div>
    <div class="tabsBox">
      <div class="tabs">
        <div
            :class="item.dictLabel == active ? 'active tabsItem' : 'tabsItem'"
            v-for="(item, index) in tabs"
            :key="index"
            @click="tabClick(item)"
        >
          <div></div>
          <p>{{ item.dictLabel }}</p>
        </div>
      </div>
      <div class="listBox" v-if="!show">
        <div class="list" v-for="(item, index) in rightList" :key="index">
          <div class="left" @click="contentClick(item)">
            <img src="../../assets/guide/icon.png" style="width: 70px;height: 70px;" alt=""/>
          </div>
          <div class="right" @click="contentClick(item)">
            <p>{{ item.webTitle }}</p>
            <div style="width:100%;height: 30px; font-weight:600;font-size: 12px;">
              <span>发布时间：</span>
              <span> {{ item.newsTime }}</span>
            </div>
            <span>{{ item.introduction }}</span>
          </div>
        </div>
        <el-pagination
            class="pageStyle"
            background
            layout="prev, pager, next"
            :page-size="10"
            :current-page="current"
            @current-change="handleCurrentChange"
            :total="total"
            v-if="total > 10"
        >
        </el-pagination>
      </div>
      <div class="content" v-else>
        <div class="title">
          <span @click="back()"><i class="el-icon-arrow-left"></i>返回</span>
          <p>{{ title }}</p>
        </div>
        <div style="width:100%;height: 30px; ">
          <span>发布时间：</span>
          <span> {{ newsTime }}</span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <span>来源：</span>
          <span> {{ newsSource }}</span>
        </div>
        <div style="border-bottom: 1px solid #dddddd;margin-top: 20px;margin-bottom: 20px; "></div>
        <div v-html="content"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      rightList: [],
      show: false,
      active: "",
      content: "",
      title: "",
      newsTime: "",
      newsSource: "",
      total: 0,
      current: 1,
      tabs: [],
      tabValue: "",
    };
  },
  created() {
    this.getTabs();
  },
  methods: {
    handleCurrentChange(val) {
      this.current = val;
      console.log(2);
      this.getList();
    },
    back() {
      this.show = false;
    },
    getTabs() {
      this.$axios
        .post("https://platformdev.mycntrade.com/api/web/business/queryTypes")
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.tabs = res.data;
            this.active = this.tabs[0].dictLabel;
            this.tabValue = this.tabs[0].dictValue;
            this.current = 1;
            this.getList();
          }
        });
    },
    // getTabs() {
    //   this.$axios
    //       .get("https://news.mycntrade.com/api/zm/queryTypeList")
    //       .then((res) => {
    //         console.log(res);
    //         if (res) {
    //           this.tabs = res;
    //           this.active = this.tabs[0].dictLabel;
    //           this.tabValue = this.tabs[0].dictValue;
    //           this.current = 1;
    //           this.getList();
    //         }
    //       });
    // },
    getList() {
      let opt= new FormData();
      opt.append('typeId', this.tabValue);
      opt.append('pageNum', this.current);
      opt.append('pageSize', '10');
      this.$axios
        .post("https://platformdev.mycntrade.com/api/web/business/queryTypeList", opt)
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.total = res.data.total;
            this.rightList = res.data.rows;
            this.show = false;
          }
        });
    },
    // getList() {
    //   let opt = new FormData();
    //   opt.append('type', this.tabValue);
    //   opt.append('pageNum', this.current);
    //   opt.append('pageSize', '10');
    //   this.$axios
    //       .post("https://news.mycntrade.com/api/zm/queryHomePageList", opt)
    //       .then((res) => {
    //         console.log(res);
    //         if (res) {
    //           this.total = res.count;
    //           this.rightList = JSON.parse(res.data);
    //           this.show = false;
    //         }
    //       });
    // },
    contentClick(val) {
      console.log(val);
      this.content = val.context;
      this.title = val.webTitle;
      this.newsSource = val.newsSource;
      this.newsTime = val.newsTime;
      this.show = true;
    },
    tabClick(val) {
      this.active = val.dictLabel;
      this.tabValue = val.dictValue;
      this.current = 1;
      this.getList()

    },
  },
};
</script>

<style lang="scss" scoped>
.guide {
  font-weight: 400;
}

.pageStyle {
  margin-top: 20px;
  text-align: center;
}

img {
  display: block;
}

.header {
  background: #2a50b7;

  img {
    margin: 0 auto;
  }
}

.tabsBox {
  @media screen and (min-width: 1440px) {
    width: 1350px;
  }
  width: 1080px;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 100px;
  display: flex;

  .tabs {
    margin-right: 16px;

    .tabsItem {
      width: 260px;
      height: 80px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      cursor: pointer;

      div {
        width: 15px;
        height: 80px;
        background: #dddddd;
        margin-right: 29px;
      }

      p {
        font-size: 20px;
        line-height: 33px;
        color: #999999;
      }
    }

    .active {
      background: #f6f6f6;

      div {
        background: #2a50b7;
      }

      p {
        color: #666666;
      }
    }
  }
}

.content {
  min-height: 700px;
  border: 1px solid #dddddd;
  padding: 0 100px;
  padding-bottom: 70px;
  font-size: 16px;
  line-height: 32px;

  .title {
    position: relative;

    span {
      position: absolute;
      left: -90px;
      top: 10px;
      font-size: 20px;
      color: #999;
      cursor: pointer;
    }

    p {
      font-size: 33px;
      line-height: 46px;
      color: #2a50b7;
      padding-top: 35px;
      text-align: center;

      margin-bottom: 30px;
    }
  }
}

.list {
  padding: 37px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dddddd;

  .left {
    margin-right: 33px;
    margin-left: 25px;
    cursor: pointer;
  }

  .right {
    cursor: pointer;

    p {
      font-size: 24px;
      line-height: 33px;
      color: #2a50b7;
    }

    span {
      font-size: 16px;
      line-height: 33px;
      color: #999999;
    }
  }
}
</style>
